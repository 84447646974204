import React, { useEffect, useState } from "react";
import styled from "styled-components";

import QuestionsApi from "../api/questions-api";
import CtaForm from "../v2/CTA/CtaForm";
import CtaInitialMessage from "../v2/CTA/CtaInitialMessage";
import CtaThankYou from "../v2/CTA/CtaThankYou";
import ctaMainActions from "../components/modules/ctaMainActions";
import { getLanguage } from "../utils/localization";
import useAnalytics from "../utils/useAnalytics";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.primaryColor};
  border-radius: ${(props) => `${props.borderRadius}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;

  ${(props) =>
    props.isArmstrongOntario &&
    `
    font-weight: 700 !important;
    font-size: 13px !important;
    font-family: "vwheadweb", sans-serif !important;
  `}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const CtaView = ({
  locationId,
  initialTextMessage,
  primaryColor,
  textColor,
  data,
  initialIndex = 0,
}) => {
  const [step, setStep] = useState(initialIndex);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [isSmallWidth, setIsSmallWidth] = useState();
  const [language, setLanguage] = useState();
  const [settings, setSettings] = useState(data);
  const [widgetDisabled, setWidgetDisabled] = useState(false);
  const [widgetInvisible, setWidgetInvisible] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const {
    sendFormSubmissionEvent,
    sendCtaInteractionEvent,
    sendFormEngagementEvent,
  } = useAnalytics(settings?.GATrackingNumber);
  const [isArmstrongOntario, setIsArmstrongOntario] = useState(false);
  const [locationIdValue, setLocationIdValue] = useState(locationId);
  const [vehicleData, setVehicleData] = useState();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("locationId");
    setLocationIdValue(id);
  }, []);

  useEffect(() => {
    setWidgetDisabled(
      settings?.location_disabled || settings?.organization_disabled
    );
  }, [settings]);

  useEffect(() => {
    if (settings?.conditions.url && currentUrl) {
      checkVisbitility();
    }
  }, [settings, currentUrl]);

  useEffect(() => {
    if (locationIdValue) {
      checkIsArmstrongOntarioClient();
    }
  }, [locationIdValue]);

  useEffect(() => {
    // add event listener when url haschanged
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const currentUrl = urlParams.get("currentUrl");
    const initialIdex = urlParams.get("initialIndex");
    if (initialIdex === 1 || initialIdex === "1") {
      setStep(1);
    }
    setCurrentUrl(currentUrl);
  }, []);

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromCta);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromCta);
  }, []);

  const onMessageReceivedFromCta = ({ data }) => {
    if (data.type === "MATADOR-CTA-UPDATE-URL") {
      if (data.url) {
        setCurrentUrl(data.url);
      }
    }
  };

  const getVehicleData = () => {
    const onMessageReceived = (event) => {
      const {
        vin,
        make,
        model,
        year,
        trim,
        stock,
        mileage,
        exteriorcolor,
        interiorcolor,
      } = event.data;

      const data = {
        ...(vin && { vin }),
        ...(make && { make }),
        ...(model && { model }),
        ...(year && { year: year?.toString() }),
        ...(trim && { trim }),
        ...(stock && { stock: stock?.toString() }),
        ...(mileage && { mileage: mileage?.toString() }),
      };

      if (exteriorcolor || interiorcolor) {
        data.color = {
          ...(exteriorcolor && { exterior: exteriorcolor }),
          ...(interiorcolor && { interior: interiorcolor }),
        };
      }

      setVehicleData([data]);
    };

    window.addEventListener("message", onMessageReceived);

    return () => {
      window.removeEventListener("message", onMessageReceived);
    };
  };

  const updateDimensions = ({ id, height, width, fullWidth }) => {
    window.top.postMessage(
      {
        type: "MATADOR-CTA-DIMENSIONS",
        id,
        height,
        width,
        fullWidth,
      },
      "*"
    );
  };

  const checkIsArmstrongOntarioClient = () => {
    setIsArmstrongOntario(
      locationIdValue === "6616c6855428530013bd626d" ||
        locationIdValue === "660c30501bc7130013730ea0"
    );
  };

  const checkVisbitility = () => {
    if (settings?.conditions.url) {
      let shouldShow = shouldDisplayContent(settings.conditions);
      setWidgetInvisible(!shouldShow);
    }
  };

  function shouldDisplayContent(data) {
    let hideConditionMatched = false;
    let showConditionMatched = false;

    data.url.forEach((condition) => {
      let processedLink = condition.link
        .replace(/(^\w+:|^)\/\/(www\.)?/, "")
        .replace(/\/$/, "");
      let conditionMatch = false;

      switch (condition.condition) {
        case "contains":
          conditionMatch = currentUrl.includes(processedLink);
          break;
        case "starts_with":
          conditionMatch = currentUrl.startsWith(processedLink);
          break;
        case "matches":
          conditionMatch = currentUrl === processedLink;
          break;
        case "ends_with":
          conditionMatch = currentUrl.endsWith(processedLink);
          break;
        default:
          console.warn(`Unknown condition type: ${condition.condition}`);
          break;
      }

      if (conditionMatch) {
        if (condition.action === "hide") {
          hideConditionMatched = true;
        } else if (condition.action === "show") {
          showConditionMatched = true;
        }
      }
    });

    return !hideConditionMatched || showConditionMatched;
  }

  useEffect(() => {
    sendFormEngagementEvent({ widget: "Integrated CTA" });
  }, [name, phoneNumber, sendFormEngagementEvent]);

  useEffect(() => {
    if (locationIdValue) {
      getData();
    }
  }, [locationIdValue]);

  const getData = async () => {
    let response = await ctaMainActions.getIntegratedCta(locationIdValue);
    if (response.data) {
      setSettings(response.data);
      const { height, width, fullWidth, _location_id } = response.data;
      updateDimensions({ id: _location_id, height, width, fullWidth });
    }
  };

  useEffect(() => {
    if (settings) {
      setTimeout(() => {
        checkSmallWidth();
      }, 100);
    }
  }, [settings]);

  const gtag = () => {
    sendFormSubmissionEvent({ widget: "Integrated CTA" });
  };

  const checkSmallWidth = () => {
    const parentEl = document.getElementById("cta-view-content");
    if (parentEl) {
      if (parentEl.offsetWidth < 300) {
        setIsSmallWidth(true);
      }
    }
  };

  const setLanguageValue = () => {
    let languageValue =
      document.getElementsByTagName("html")[0].getAttribute("lang") || "en";
    if (languageValue === "es") {
      languageValue = "sp";
    }

    if (languageValue.includes("-")) {
      languageValue = languageValue.split("-")[0];
    }

    setLanguage(languageValue);
  };

  useEffect(() => {
    setLanguageValue();
    getVehicleData();
  }, []);

  const isMobileDevice = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return true;
    } else if (userAgent.match(/Android/i)) {
      return true;
    } else {
      return false;
    }
  };

  if (!settings || settings.device === "NONE") return null;

  if (settings.device !== "ALL") {
    if (isMobileDevice() && settings.device !== "MOBILE") return null;

    if (!isMobileDevice() && settings.device !== "DESKTOP") return null;
  }

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const submitForm = async () => {
    const param = {
      phone_number: phoneNumber,
      firstName: (name || "").trim(),
      link: currentUrl,
      question: "Text me - Integrated CTA",
      language: getLanguage(language),
    };

    if (vehicleData && vehicleData.length > 0) {
      const nonEmptyVehicleData = vehicleData.filter(
        (data) => Object.keys(data).length > 0
      );
      if (nonEmptyVehicleData.length > 0) {
        param.vehicle = nonEmptyVehicleData;

        const vehicleDataString = nonEmptyVehicleData
          .map((data) => {
            return Object.keys(data)
              .map((key) => {
                return `${data[key]}`;
              })
              .join(" ");
          })
          .join(" ");
        param.question = `I'm interested in this ${vehicleDataString}`;
      }
    }

    QuestionsApi.textMe({ locationId: locationIdValue, param });
    gtag();
    nextStep();
  };

  const onClick = () => {
    sendCtaInteractionEvent({ widget: "Integrated CTA" });
  };

  const getInitialMessage = () => {
    const data = settings.initialTextMessage.find(
      (x) => x.language === language
    );

    if (initialTextMessage) {
      return initialTextMessage;
    }

    return data ? data.text : "";
  };

  const getContent = () => {
    if (step === 0) {
      return (
        <CtaInitialMessage
          text={getInitialMessage()}
          textColor={textColor || settings.textColor}
          onClick={nextStep}
          fontSize={settings.fontSize}
        />
      );
    }

    if (step === 1) {
      return (
        <CtaForm
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          onSubmit={submitForm}
          name={name}
          setName={setName}
          primaryColor={primaryColor || settings.primaryColor}
          width={isSmallWidth ? "S" : "M"}
          organizationCountryCode={settings?.organizationCountryCode}
          textColor={textColor || settings.textColor}
          termsLink={settings.termsLink}
        />
      );
    }

    if (step === 2) {
      return (
        <CtaThankYou
          textColor={textColor || settings.textColor}
          fontSize={settings.fontSize}
        />
      );
    }
  };

  if (widgetDisabled || widgetInvisible || !settings) return null;

  return (
    <Container
      primaryColor={primaryColor || settings.primaryColor}
      textColor={textColor || settings.textColor}
      fullWidth={settings.fullWidth}
      borderRadius={settings.borderRadius}
      id="cta-view-content"
      onClick={onClick}
      isArmstrongOntario={isArmstrongOntario}
    >
      <Content>{getContent()}</Content>
    </Container>
  );
};

export default CtaView;
